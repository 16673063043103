import BillyWithBooks from "../../Images/BillyWithBooks-min.jpg";
import BillyWithBooksCropped from "../../Images/BillyWithBooksCropped-min.jpg";
import { Box, Dialog, FormLabel, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IntroducingSeamore from "../../Images/IntroducingSeamore-min.jpg";
import RightArrowIcon from "@mui/icons-material/East";
import Seamore from "../../Images/Seamore-min.png";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./books.module.scss";

type BooksProps = {
  //ga4: any
  isMobileDevice: boolean;
};

const Books = ({ isMobileDevice }: BooksProps) => {
  const [imageHeight, setImageHeight] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [isVideoDisplayed, setIsVideoDisplayed] = useState(false);
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    var height = isMobileDevice ? screenHeight - 260 : screenHeight - 415;
    var width = isMobileDevice ? (height / 840) * 1120 : (height / 546) * 863;
    setImageHeight(`${height}px`);
    setImageWidth(`${width}px`);

    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 198px)`
        : `calc(${screenHeight}px - 346px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      className={styles.pageContent}
      style={{ justifyContent: "center", height: pageContentHeight }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box
        width={imageWidth}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "10px 30px 30px 30px",
        }}
      >
        <img
          src={isMobileDevice ? BillyWithBooks : BillyWithBooksCropped}
          style={{
            maxHeight: imageHeight,
            margin: "auto",
            objectFit: "fill",
            width: imageWidth,
          }}
          alt="Check out Seamore books for more Bible stories."
        />
      </Box>
      <Box
        width={screenWidth <= 1250 ? "80vw" : "30vw"}
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "0 10px 30px 10px",
        }}
      >
        <Box
          sx={{
            color: "black",
            fontSize: screenWidth <= 1250 ? "24px" : "28px",
          }}
        >
          <Box
            sx={{ margin: screenWidth <= 1250 ? "-20px 0 5px 0" : "20px 0" }}
          >
            <span>To learn more Bible stories, check out the </span>
            <Link
              href="https://jollyjourneypublishing.com"
              target="_blank"
              rel="noreferrer"
            >
              Seamore books
            </Link>
            <span>.</span>
          </Box>
          <Box display="flex" flexDirection="row" width="50%">
            {!isVideoDisplayed && (
              <Box
                className={styles.bounceHorizontalStyle}
                display="flex"
                sx={{
                  color: "gray",
                  justifyContent: "center",
                  margin: "auto",
                  marginTop: "110px",
                }}
              >
                <Typography
                  style={{ textAlign: "right" }}
                  fontSize={screenWidth <= 1250 ? "20px" : "22px"}
                >
                  click on Seamore
                </Typography>
                <RightArrowIcon
                  sx={{ alignSelf: "center", marginLeft: "7px" }}
                />
              </Box>
            )}
            <Box
              alignSelf="flex-end"
              className={styles.seamore}
              onClick={() => setIsVideoDisplayed(true)}
              width="50%"
            >
              <img
                src={Seamore}
                alt="Seamore"
                style={{ height: "260px", margin: "0 -30px 0 -13px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isVideoDisplayed}
        onClose={() => setIsVideoDisplayed(false)}
        PaperProps={{
          sx: { margin: "5px" },
        }}
      >
        <Box className={styles.moreInfoHeader}>
          <FormLabel
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              margin: "12px 20px 10px 20px",
            }}
          >
            Introducing Seamore...
          </FormLabel>
          <CloseIcon
            className={styles.closeButton}
            sx={{
              cusrsor: "pointer",
              height: "35px",
              margin: "10px",
              width: "35px",
            }}
            onClick={() => setIsVideoDisplayed(false)}
          />
        </Box>
        <Box>
          <video
            width={Math.min(550, screenWidth - 10)}
            height={Math.min(550, screenWidth - 10)}
            controls
            autoPlay
            loop
            muted
            poster={IntroducingSeamore}
          >
            <source src="/images/AboutSeamore.mp4" type="video/mp4" />
            <p>
              Your browser does not support the video tag. Please click the
              following link to download the video.
            </p>
            <p>
              <a href="/images/AboutSeamore.mp4">AboutSeamore.mp4 download</a>
            </p>
          </video>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Books;
