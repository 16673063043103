import billyWithPuppets from "../../Images/BillyPlayingWithPuppets-min.jpg";
import { Box, FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./aboutUs.module.scss";

type AboutUsProps = {
  //ga4: any,
  isMobileDevice: boolean;
};

const AboutUs = ({ isMobileDevice }: AboutUsProps) => {
  const [imageHeight, setImageHeight] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    var height = isMobileDevice ? screenHeight - 260 : screenHeight - 415;
    var width = (height / 1499) * 1125;
    setImageHeight(`${height}px`);
    setImageWidth(`${width}px`);

    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 198px)`
        : `calc(${screenHeight}px - 346px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      className={styles.pageContent}
      style={{ justifyContent: "center", height: pageContentHeight }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box
        height={imageHeight}
        width={imageWidth}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "30px",
        }}
      >
        <img
          style={{
            height: imageHeight,
            margin: "auto",
            objectFit: "fill",
            width: imageWidth,
          }}
          src={billyWithPuppets}
          alt="The mission of the Cornbread Kids is to teach children Bible stories."
        />
      </Box>
      <Box
        width={isMobileDevice ? "80vw" : "50vw"}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "0 10px 30px 10px",
        }}
      >
        <FormLabel
          sx={{
            color: "black",
            flexGrow: "1",
            fontSize: isMobileDevice ? "24px" : "32px",
          }}
        >
          The mission of the Cornbread Kids is to teach children Bible stories.
          We also want them to learn how to live a victorious Christian life,
          how powerful and trustworthy God is and how immeasurably He loves
          them.
        </FormLabel>
      </Box>
    </Box>
  );
};

export default AboutUs;
