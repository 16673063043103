import BibleBedtimeStoriesLogo from "../../Images/BibleBedtimeStories-min.jpg";
import { Box, Link } from "@mui/material";
import GA4React from "ga-4-react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./biblebedtimestories.module.scss";

type BibleBedtimeStoriesProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const BibleBedtimeStories = ({
  ga4react,
  isMobileDevice,
}: BibleBedtimeStoriesProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 228px)`
        : `calc(${screenHeight}px - 376px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      bgcolor="#CCCCCC"
      className={styles.pageContent}
      style={{
        height: pageContentHeight,
        justifyContent: "center",
        margin: "0 10px 10px 10px",
        padding: "10px 0",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "15px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#666666",
          borderRadius: 2,
        },
      }}
    >
      <Box
        bgcolor="white"
        borderRadius="7px"
        display="flex"
        flexDirection={isMobileDevice ? "column" : "row"}
        margin="10px auto 10px auto"
        overflow-x="hidden"
        padding="10px 10px 30px 10px"
        width="80%"
      >
        <Box margin={isMobileDevice ? "30px auto 10px auto" : "auto"}>
          <Link
            href="https://BibleBedtimeStories4Kids.com"
            onClick={() =>
              ga4react.event(
                "Bible Bedtime Stories YouTube Channel Clicked",
                "Bible Bedtime Stories YouTube",
                "Click",
                false
              )
            }
            rel="noreferrer"
            sx={{ margin: "35px 5px" }}
            target="_blank"
            underline="none"
          >
            <img
              className={styles.image}
              src={BibleBedtimeStoriesLogo}
              alt="Bible Bedtime Stories logo"
              style={{ height: "260px" }}
            />
          </Link>
        </Box>

        <Box
          sx={{
            fontSize: isMobileDevice ? "20px" : "24px",
            margin: isMobileDevice ? "10px 20px auto 20px" : "auto",
          }}
        >
          <span>To learn more Bible stories, check out the </span>
          <Link
            href="https://www.youtube.com/@biblebedtimestoriesforkids"
            target="_blank"
            rel="noreferrer"
            underline="none"
            onClick={() =>
              ga4react.event(
                "Bible Bedtime Stories Website Clicked",
                "Bible Bedtime Stories Website",
                "Click",
                false
              )
            }
          >
            Bible Bedtime Stories
          </Link>
          <span> YouTube channel.</span>
          <br />
          <br />
          <span>
            Follow along with the words on the screen to improve your reading
            abilities or just enjoy the illustrations and listening to the
            story.
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default BibleBedtimeStories;
