import { menuChoices } from "./enums";

export const getTabName = (selectedTab: number) => {
  switch (selectedTab) {
    case menuChoices.ABOUT:
      return "ABOUT US";
    case menuChoices.BIBLEBEDTIMESTORIES:
      return "BIBLE STORIES";
    case menuChoices.BLOGS:
      return "BLOGS";
    case menuChoices.BOOKS:
      return "BOOKS";
    case menuChoices.RESOURCES:
      return "RESOURCES";
    case menuChoices.SHOWS:
      return "SHOWS";
    case menuChoices.TEAM:
      return "TEAM";
  }
};
