import { Box, FormLabel, Link } from "@mui/material";
import GA4React from "ga-4-react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./resources.module.scss";

type ResourcesProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const Resources = ({ ga4react, isMobileDevice }: ResourcesProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 228px)`
        : `calc(${screenHeight}px - 376px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      bgcolor="#CCCCCC"
      className={styles.pageContent}
      style={{
        height: pageContentHeight,
        justifyContent: "center",
        margin: "0 10px 10px 10px",
        padding: "10px 0",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "15px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#666666",
          borderRadius: 2,
        },
      }}
    >
      <Box
        bgcolor="white"
        borderRadius="7px"
        display="flex"
        flexDirection="column"
        margin="10px auto 10px auto"
        overflow-x="hidden"
        padding="10px 10px 30px 10px"
        width="80%"
      >
        <Box
          sx={{
            color: "black",
            fontSize: "28px",
            fontWeight: "bold",
            margin: "30px auto",
          }}
        >
          <FormLabel
            sx={{
              alignItems: "center",
              color: "black",
              display: "flex",
              fontSize: "28px",
              fontWeight: "bold",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            Puzzles
          </FormLabel>
          <Box
            sx={{
              fontSize: isMobileDevice ? "20px" : "24px",
              margin: "30px auto",
            }}
          >
            <Link
              href="/Resources/King Joash Word Search Puzzle.pdf"
              target="_blank"
              rel="noreferrer"
              underline="none"
              onClick={() =>
                ga4react.event(
                  "Puzzle Download Clicked",
                  "King Joash Word Search",
                  "Click",
                  false
                )
              }
            >
              King Joash Word Search
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Resources;
